import serialize from 'form-serialize';

const getEvent = (name) => {
    let event;

    try {
        event = new CustomEvent(name);
    } catch (e) {
        event = document.createEvent('Event');
        event.initEvent(name, true, true);
    }

    return event;
};

const Newsletter = (el) => {
    const form = document.querySelector('.newsletter__form');
    const termsCheckbox = document.getElementById('terms');
    const submitBtn = el.querySelector('button[type="submit"]');

    const setTermsApproved = (e) => {
        e.target.checked
        ? submitBtn.removeAttribute('disabled')
        : submitBtn.setAttribute('disabled', true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const endpoint = form.getAttribute('action');
        const method = form.getAttribute('method');
        let formBody = serialize(form, { hash: true });

        let opts = {
            method,
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        if (method === 'POST') {
            opts['body'] = JSON.stringify(formBody);
        };

        const r = await fetch(endpoint, opts);
        const data = await r.json();

        if (data.success) {
            let event = getEvent('newsletterSubscription');
            event['data'] = {
                snackBarLabel: form.getAttribute('data-success-message')
            };
            window.dispatchEvent(event);
        }
    };

    termsCheckbox.addEventListener('click', setTermsApproved);
    form.addEventListener('submit', handleSubmit);
};

export default Newsletter;
