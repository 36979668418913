import throttle from 'lodash/throttle';

const horizontalScroller = (el) => {
    const scroller = el.querySelector('.js-horizontal-scroller__scroller');
    const contentContainer = scroller.querySelector(`.js-horizontal-scroller__scroller > *`);
    let scrollPosition = 0;
    let contentWidth = 0;
    let scrollPositionStart = true;
    let scrollPositionEnd = false;

    const checkScrollPosition = () => {
        scrollPosition = scroller.scrollLeft + scroller.getBoundingClientRect().width;
        contentWidth = contentContainer.scrollWidth;
        scrollPositionStart = scroller.scrollLeft === 0;
        scrollPositionEnd = (scrollPosition += 20) >= contentWidth;
    };

    const showHideElementsBasedOnScroll = () => {
        el.classList.toggle(
            'js-horizontal-scroller--overflow-right',
            !scrollPositionEnd
        );
        el.classList.toggle(
            'js-horizontal-scroller--overflow-left',
            !scrollPositionStart
        );
    };

    const handleScrollAndResize = () => {
        checkScrollPosition();
        showHideElementsBasedOnScroll();
    };

    const init = () => {
        checkScrollPosition();
        showHideElementsBasedOnScroll();

        scroller.addEventListener('scroll', throttle(handleScrollAndResize, 100));
        window.addEventListener('resize', throttle(handleScrollAndResize, 100));
    };

    init();
};

export default horizontalScroller;
