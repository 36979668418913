const dataLayer = window.dataLayer || [];

const facetFilter = () => {
    const facets = document.querySelectorAll('.facet__input');

    for (let facet of facets) {
        facet.addEventListener('change', e => dataLayer.push({
            'event': 'Filter Categories',
            'analyticsInfo': e.target.value
        }));
    }
};

facetFilter();
