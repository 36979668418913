import { default as LF } from 'livefilter';
import template from 'lodash/template';
import forEach from 'lodash/forEach';

import GetEvent from '../GetEvent';

const LiveFilter = (el) => {
        const update = (data) => {
        const contentContainer = document.getElementById(`${el.getAttribute('aria-controls')}`) || document.createElement('div');
        const resultCount = document.querySelector('.js-filter-result-count') || document.createElement('div');
        const { results, nextPageUrl, searchResultCount } = data;
        let event = GetEvent('liveFilterUpdate');

        if (results) {
            const templateId = el.getAttribute('data-template-id');
            const tmpl = document.getElementById(templateId);
            const count = data.totalItems;

            searchResultCount
                ? resultCount.innerText = searchResultCount
                : resultCount.innerText = "";

			event['data'] = { count, nextPageUrl };

            // If there's a template then use that
            // Else we assume raw markup
            if (tmpl) {
                const resultTemplate = tmpl.innerHTML;

                const templateFn = template(
                    resultTemplate,
                    { 'imports': { 'forEach': forEach } }
                );

                contentContainer.innerHTML = templateFn({ results });
            } else {
                contentContainer.innerHTML = results;
            }

            window.dispatchEvent(event);
        }
    };

    const init = () => {
        const subscribers = (el.getAttribute('data-subscribers')) ? el.getAttribute('data-subscribers').split(',') : '';
        const endpoint = (el.getAttribute('data-endpoint')) ? el.getAttribute('data-endpoint') : '';

        let opts = {
            additionalHeaders: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            triggers: {
                'change': 'input[type="radio"]:not(.js-live-filter-ignore), input[type="checkbox"]:not(.js-live-filter-ignore), input[type="hidden"]:not(.js-live-filter-ignore), input[type="text"]:not(.js-live-filter-ignore), select:not(.js-live-filter-ignore)',
                'keyup': {
                    selector: 'input[type="text"]:not(.js-live-filter-ignore), input[type="search"]:not(.js-live-filter-ignore)',
                    debounce: true
                }
            },
            afterFetch: update,
            preventSubmit: false,
            subscribers,
            action: endpoint
        };

        new LF(el, opts);
    };

    init();
};

export default LiveFilter;
