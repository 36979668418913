import SearchAsYouType from 'searchasyoutype';

const keys = {
    escape: 27,
    up: 38,
    down: 40
};

const isDescendant = (parent, child) => {
    var node = child.parentNode;

    while (node != null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }

    return false;
};

const AutoComplete = (el) => {
    const form = document.getElementById(el.getAttribute('data-autocomplete-form-id')) || document.createElement('div');
    const baseUrl = form.getAttribute('action') || '';
    const searchField = el.querySelector('input[type="search"]');
    const endpoint = el.getAttribute('data-autocomplete-endpoint') || '';
    let resultsContainer = null;
    let resultsContainerVisible = false;

    const template =
        `<% var keyword = keyword || "%;234zkeoi" %><% forEach(results, function(result) { %>
            <a href="${baseUrl}<%= result.query %>" class="search-field__autocomplete-result" tabindex="-1">
                <div class="search-field__autocomplete-result-label"><%= result.label.replace(keyword, "<strong>" + keyword + "</strong>") %></div>
            </a>
        <% }); %>`;

    const init = () => {
        addEventListeners();
        initSearchAsYouType();

        // Element created by SearchAsYouType on init
        resultsContainer = el.querySelector('.search-field__autocomplete-results');
    };

    const outsideClick = (e) => {
        const target = e.target;

        if (!isDescendant(el, target)) {
            hideResultsContainer();
        }
    };

    const hideResultsContainer = () => {
        resultsContainer.setAttribute('tabindex', '-1');
        resultsContainer.classList.remove('search-field__autocomplete-results--visible');

        document.removeEventListener('click', outsideClick);
        document.removeEventListener('keydown', onKeydown);
        resultsContainerVisible = false;
    };

    const showResultsContainer = () => {
        resultsContainer.removeAttribute('tabindex');
        resultsContainer.classList.add('search-field__autocomplete-results--visible');

        document.addEventListener('click', outsideClick);
        document.addEventListener('keydown', onKeydown);
        resultsContainerVisible = true;
    };

    const focus = (el) => {
        el.focus();
        searchField.value = el.innerText;
    };

    const focusOnNext = () => {
        if (document.activeElement.parentNode === resultsContainer) {
            // If already in loop.
            if (document.activeElement.nextElementSibling) {
                // Focus on next one.
                focus(document.activeElement.nextElementSibling);
            } else {
                // Focus on first
                focus(document.activeElement.parentNode.firstElementChild);
            }
        } else {
            // If still on input element
            if (resultsContainer.querySelector('a')) {
                focus(resultsContainer.querySelector('a'));
            }
        }
    };

    const focusOnPrevious = () => {
        if (document.activeElement.parentNode === resultsContainer) {
            // If already in loop.
            if (document.activeElement.previousElementSibling) {
                // Focus on next one.
                focus(document.activeElement.previousElementSibling);
            } else {
                // Focus on first
                focus(document.activeElement.parentNode.lastElementChild);
            }
        } else {
            // If still on input element
            if (resultsContainer.querySelectorAll('a')) {
                focus(resultsContainer.querySelectorAll('a')[resultsContainer.querySelectorAll('a').length - 1]);
            }
        }
    };

    const onKeydown = (e) => {
        const charCode = (typeof e.which === 'number') ? e.which : e.keyCode;

        switch (charCode) {
            case keys.escape:
                hideResultsContainer();
                break;

            case keys.down:
                e.preventDefault();
                focusOnNext();
                break;

            case keys.up:
                e.preventDefault();
                focusOnPrevious();
                break;

            default:
                // Do nothing.
        }
    };

    const onTypingInSearchfield = (e) => {
        const charCode = (typeof e.which === 'number') ? e.which : e.keyCode;

        if (charCode !== keys.escape && !resultsContainerVisible) {
            showResultsContainer();
        }
    };

    const onFocusedSearchfield = (e) => {
        if (!resultsContainerVisible) {
            showResultsContainer();
        }
    };

    const addEventListeners = () => {
        searchField.addEventListener('input', onTypingInSearchfield);
        searchField.addEventListener('focus', onFocusedSearchfield);
    };

    const initSearchAsYouType = () => {
        new SearchAsYouType(el, {
            minStringLength: 3,
            inputSelector: 'input[type="search"]',
            resultsSelector: '.search-field__autocomplete-results',
            template,
            endpoint
        });
    };

    init();
};

export default AutoComplete;
