import $clamp from 'clamp-js';

const lineClamp = () => {
    const els = document.querySelectorAll('.line-clamp');

    for (let el of els) {
        $clamp(el, { clamp: 2 });
    }
};

lineClamp();

window.addEventListener('loadMoreUpdate', () => {
    lineClamp();
});
