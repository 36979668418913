const ExpandableSearch = el => {
	const id = el.getAttribute("id");
	const searchInput = el.querySelector(".search-field__input");
    const trigger = document.querySelector(`[aria-controls="${id}"]`);

	const toggle = () => {
		trigger.getAttribute("aria-expanded") === "true" ? close() : open();
	};

	const open = () => {
		el.setAttribute("aria-hidden", "false");
		trigger.setAttribute("aria-expanded", "true");
		searchInput.focus();
	};

	const close = () => {
		el.setAttribute("aria-hidden", "true");
		trigger.setAttribute("aria-expanded", "false");
	};

	trigger.addEventListener("click", toggle);
	searchInput.addEventListener("focusout", e => {
		if (e.relatedTarget !== trigger && !e.relatedTarget.classList.contains("search-field__autocomplete-result")) {
			close();
		}
	});
};

export default ExpandableSearch;
