const snackBar = (el) => {
    const labelEl = el.querySelector('.snack-bar__label');
    let timer = null;

    const onUpdate = (e) => {
        const { snackBarLabel } = e.data;

        if (snackBarLabel) {
            labelEl.innerHTML = snackBarLabel;
            show();
        }
    };

    const show = () => {
        el.classList.add('snack-bar--visible');
        window.addEventListener('scroll', hide);

        clearTimeout(timer);
        timer = setTimeout(() => {
            hide();
        }, 5000);
    };

    const hide = () => {
        el.classList.remove('snack-bar--visible');
        window.removeEventListener('scroll', hide);
        clearTimeout(timer);
    };

    window.addEventListener('newsletterSubscription', onUpdate);
};

export default snackBar;
