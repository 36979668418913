import ExpandableSearch from './ExpandableSearch';
import AutoComplete from './Autocomplete';

const searchField = document.getElementById('expandable-search');
const autoCompleteSearchFields = document.querySelectorAll('.search-field--autocomplete');

if (searchField) {
    ExpandableSearch(searchField);
}

for (let el of autoCompleteSearchFields) {
    AutoComplete(el);
}
