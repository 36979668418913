const servingsSelect = (el) => {
    const ingredientsContainer = document.querySelector(
        `.${el.getAttribute('data-ingredients-container')}`
    );

    const update = async (e) => {
        const endpoint = el.getAttribute('data-endpoint');
        const contentId = el.getAttribute('data-content-id');
        const { value } = e.target;

        const r = await fetch(`${endpoint}?contentId=${contentId}&servings=${value}`, {
            credentials: 'include',
            headers: {
                'Accept': 'text/html',
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        const data = await r.text();

        if (data) {
            ingredientsContainer.innerHTML = data;
        }
    };

    el.addEventListener('change', e => update(e));
};

export default servingsSelect;
