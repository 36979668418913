/* eslint-disable */
console.log(`--- Currently running ${VERSION} ---`);
/* eslint-disable */

// External
import "focus-visible";
import "intersection-observer"; // IE11 polyfill for Intersection Observer API

// Internal
import "./chrome/navbar";
import "./chrome/foldout";

import "./functions/animationObserver";
import "./functions/servingsSelect";
import "./functions/lineclamp";
import "./functions/load-more";
import "./functions/horizontalScroller";
import "./functions/live-filter";

import "./components/hero";
import "./components/newsletter";
import "./components/search-field";
import "./components/lottie-animation";
import "./components/accordion";
import "./components/flavour-picker";
import "./components/form/notched-outline";
import "./components/form/upload-file";
import "./components/media-content-block";
import "./components/card";
import "./components/wide-info-block";
import "./components/filter";
import "./components/filter-legend";
import "./components/snack-bar";
import "./components/cookie-banner";
import "./components/carousel";

import "./analytics/filter";
import './analytics/newsletter';
// Init
