// Value and id of all filter inputs must be equilent for correct work this component
import template from 'lodash/template';
import serialize from 'form-serialize';
import GetEvent from '../../functions/GetEvent';

const createNodeWithText = (str) => {
    const div = document.createElement('div');
    div.innerHTML = str.trim();
    return div.firstChild;
};

const FilterLegend = (el) => {
    const pillList = el.querySelector('.filter-legend__items');
    const form = document.getElementById(el.getAttribute('aria-controls')) || document.createElement('div');
    const pillTemplate = document.getElementById('filter-legend-pill-tmpl').innerHTML;
    const templateFn = template(pillTemplate);
    const clearButton = el.querySelector('.filter-legend__clear-button');

    const addEventListeners = () => {
        const pills = el.querySelectorAll('.pill__input');

        window.addEventListener('liveFilterUpdate', onUpdate);

        for (let pill of pills) {
            pill.addEventListener('change', onPillClick);
        }

        if (clearButton) {
            clearButton.addEventListener('click', onClearButtonClick);
        }
    };

    const onPillClick = (e) => {
        const id = e.target.getAttribute('aria-controls');
        const input = document.getElementById(id);
        const event = GetEvent('change');

        if (input) {
            input.checked = false;

            document.getElementById('livefilter-event-trigger').dispatchEvent(event);
        }
    };

    const onClearButtonClick = (e) => {
        e.preventDefault();
        const pills = el.querySelectorAll('.pill__input');
        const event = GetEvent('change');

        for (let pill of pills) {

            const input = document.getElementById(pill.getAttribute('aria-controls')); // value and id of "source inputs" must be equilent

            if (input && (input.getAttribute('type') === 'checkbox' || input.getAttribute('type') === 'radio')) {
                input.checked = false;
            }
        }

        document.getElementById('livefilter-event-trigger').dispatchEvent(event);
    };

    const onUpdate = () => {
        const serializedForm = serialize(form, { hash: true });
        let selectedItems = [];

        let input, inputId, label;

        for (let i in serializedForm) {
            if (Array.isArray(serializedForm[i])) {
                for (let k of serializedForm[i]) {
                    input = form.querySelector(`input[value=${k}]`);
                    inputId = (input && input.getAttribute('id')) || '';
                    label = (inputId && form.querySelector(`label[for=${inputId}]`)) || null;

                    if (label) {
                        selectedItems[k] = label.textContent;
                    }
                }
            }
        }
        const activeFiltersCount = Object.keys(selectedItems).length;

        insertPills(selectedItems);
        updateFilterCounter(activeFiltersCount);

        activeFiltersCount > 0
        ? show()
        : hide();
    };

    const insertPills = (selectedItems) => {
        // Remove all pills.
        for (let item of el.querySelectorAll('.pill')) {
            item.parentNode.removeChild(item);
        }

        // Insert new pills.
        for (let key in selectedItems) {
            const filterLegendPlaceholder = el.querySelector('.filter-legend__placeholder');
            let pill = createNodeWithText(templateFn({ name: selectedItems[key], id: key }));

            pillList.insertBefore(pill, filterLegendPlaceholder);
            pill.querySelector('.pill__input').addEventListener('click', onPillClick);
        }
    };

    const updateFilterCounter = (count) => {
        const filterCounter = document.querySelector('.filter-count');

        if (filterCounter) {
            count > 0
                ? filterCounter.innerText = `(${count})`
                : filterCounter.innerText = "";
        }
    };

    const show = () => {
        el.classList.remove('filter-legend--hidden');
    };

    const hide = () => {
        el.classList.add('filter-legend--hidden');
    };

    addEventListeners();
};

export default FilterLegend;
