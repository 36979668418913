const floatingLabel = (el) => {
    const input = el.querySelector('input') || el.querySelector('textarea');
    const outline = el.querySelector('.notched-outline');
    const outlineNotch = el.querySelector('.notched-outline__notch');
    const label = el.querySelector('.notched-outline__floating-label');

    outlineNotch.style.width = `${label.offsetWidth + 16}px`;

    const set = () => {
        outline.classList.add('notched-outline--notched');
    };

    const unset = () => {
        if (input.value.length === 0) {
            outline.classList.remove('notched-outline--notched');
        }
    };

    input.addEventListener('focusin', set);
    input.addEventListener('blur', unset);
};

export default floatingLabel;
