import touchDetect from '../../functions/touchDetect';

const FlavourPickerCarousel = (el, opts) => {
    const carousel = el.querySelector('.fp__carousel');
    const carouselList = el.querySelector('.fp__carousel-list');
    const itemsToShow = 3;
    const prevBtn = el.querySelector('.fp__carousel-btn--prev');
    const nextBtn = el.querySelector('.fp__carousel-btn--next');
    const items = el.querySelectorAll('.fp__carousel-item');
    const itemWidth = (() => {
        const styles = getComputedStyle(items[0]);
        const offsetWidth = items[0].offsetWidth;

        return parseFloat(styles.marginLeft) + offsetWidth + parseFloat(styles.marginRight);
    })();
    const labelEl = el.querySelector('.fp__current-f-label');
    let activeItemLabel = '';
    let activeItemLabelColor = '';
    let pxOffset = 0; // transform offset of carousel list
    let currentIndex = 0;

    /*
    * Determines carousel start position,
    * opting for a centered layout if there's room
    *
    * @param {number} i
    */
    const setStartPosition = (i) => {
        const itemsLeftToScroll = items.length - (i + 1);

        if (itemsLeftToScroll === 0) {
            currentIndex = i - 2;
        } else if (i === 0) {
            currentIndex = i;
        } else {
            currentIndex = i - 1;
        }
    };

    /*
    * Set primary & secondary colors for
    * carousel items using data attr values
    *
    * @param {element} item
    */
    const setColorsAndActiveLabel = (item) => {
        const primaryClr = item.getAttribute('data-primary-color');
        const secondaryClr = item.getAttribute('data-secondary-color');
        const primaryEl = item.querySelector('.fp__carousel-item-circle--primary');
        const secondaryEl = item.querySelector('.fp__carousel-item-circle--secondary');

        primaryEl.style.backgroundColor = primaryClr;
        secondaryEl.style.backgroundColor = secondaryClr;
    };

    /*
    * Set inner text value and color
    * of current flavour label
    *
    * @param {element} item
    */
    const setFlavourLabel = (item) => {
        const label = item.getAttribute('data-label');
        const primaryClr = item.getAttribute('data-primary-color');

        labelEl.style.color = primaryClr;
        labelEl.innerText = label;
    };

    /*
    * Add and remove hover listener for
    * applying active label on visible items
    *
    */
    const addAndRemoveHoverListeners = () => {
        for (let item of [...items]) {
            const itemOverflowLeft = (item.offsetLeft - pxOffset) < 0;
            const itemOverflowRight = (carouselList.offsetWidth + pxOffset) <= item.offsetLeft;
            const setFlavourLabelHandler = () => setFlavourLabel(item);
            const resetFlavourLabelHandler = () => {
                labelEl.style.color = activeItemLabelColor;
                labelEl.innerText = activeItemLabel;
            };

            if (itemOverflowLeft || itemOverflowRight) {
                item.removeEventListener('mouseover', setFlavourLabelHandler);
                item.removeEventListener('mouseout', resetFlavourLabelHandler);
                item.style.pointerEvents = 'none';
            } else {
                item.addEventListener('mouseover', setFlavourLabelHandler);
                item.addEventListener('mouseout', resetFlavourLabelHandler);
                item.style.pointerEvents = 'initial';
            }
        }
    };

    /*
    * Set overflow classes to show/hide
    * carousel buttons
    *
    */
    const setOverflow = () => {
        const offset = items.length - currentIndex;

        offset > itemsToShow
            ? carousel.classList.add('fp__carousel--overflow-right')
            : carousel.classList.remove('fp__carousel--overflow-right');

        currentIndex > 0
            ? carousel.classList.add('fp__carousel--overflow-left')
            : carousel.classList.remove('fp__carousel--overflow-left');
    };

    /*
    * Initial setup of listeners, colors,
    * carousel position and overflow classes
    *
    */
    const setInitialState = () => {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.classList.contains('fp__carousel-item--active')) {
                activeItemLabel = item.getAttribute('data-label');
                activeItemLabelColor = item.getAttribute('data-primary-color');

                setStartPosition(i);
                setFlavourLabel(item);
            }

            setColorsAndActiveLabel(item);
        }

        setTransformOffset();
        setOverflow();
        addAndRemoveHoverListeners();
    };

    /*
    * Set value of transform style prop
    * on carousel list element to current offset
    *
    */
    const setTransformOffset = () => {
        pxOffset = itemWidth * currentIndex;

        const transform = `translate3d(-${pxOffset}px, 0, 0)`;
        carouselList.style.transform = transform;
    };

    /*
    * Move to next item in carousel
    */
    const next = () => {
        const offset = items.length - currentIndex;

        if (offset > itemsToShow) {
            currentIndex = currentIndex + 1;

            setOverflow();
            setTransformOffset();
            addAndRemoveHoverListeners();
        }
    };

    /*
    * Move to prev item in carousel
    */
    const prev = () => {
        if (currentIndex > 0) {
            currentIndex = currentIndex - 1;

            setOverflow();
            setTransformOffset();
            addAndRemoveHoverListeners();
        }
    };

    touchDetect(el, (direction) => {
        if (direction === 'left') {
            next();
        }

        if (direction === 'right') {
            prev();
        }
    });

    nextBtn.addEventListener('click', () => next());
    prevBtn.addEventListener('click', () => prev());
    setInitialState();
};

export default FlavourPickerCarousel;
