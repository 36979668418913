import 'whatwg-fetch';
import template from 'lodash/template';
import forEach from 'lodash/forEach';

import GetEvent from '../GetEvent';

const LoadMore = (el) => {
    const contentContainer = document.getElementById(`${el.getAttribute('aria-controls')}`) || document.createElement('div');
    const loadingIndicator = document.getElementById(el.getAttribute('data-loading-indicator')) || document.createElement('div');

    const addEventListeners = () => {
        el.addEventListener('click', onClick);
        window.addEventListener('liveFilterUpdate', updateHrefAttribute);
    };

    const onClick = (e) => {
        e.preventDefault();
        fetchData(update);
    };

    const showLoadMoreBtn = (nextPageUrl) => {
		el.classList.remove('h-hidden-from-view');
		el.setAttribute('href', nextPageUrl);
    };

    const hideLoadMoreBtn = () => {
		el.parentNode.removeChild(el);
    };

    const fetchData = async (cb) => {
        const endpoint = el.getAttribute('href');

        el.setAttribute('disabled', true);
        loadingIndicator.style.display = "inline-block";

        const r = await fetch(endpoint, {
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        const data = await r.json();

        el.removeAttribute('disabled');
        loadingIndicator.style.display = "none";
        cb(data);
    };

    const update = (data) => {
        const { results, nextPageUrl } = data;

        if (results) {
            const event = GetEvent('loadMoreUpdate');
            const templateId = el.getAttribute('data-template-id');

            // If there's a template then use that
            // Else we assume raw markup
            if (templateId) {
                const resultTemplate = document.getElementById(templateId).innerHTML;

                const templateFn = template(
                    resultTemplate,
                    { 'imports': { 'forEach': forEach } }
                );

                contentContainer.insertAdjacentHTML('beforeend', templateFn({ results }));
            } else {
                contentContainer.insertAdjacentHTML('beforeend', results);
            }

            window.dispatchEvent(event);
        }

        if (nextPageUrl) {
			showLoadMoreBtn(nextPageUrl);
        } else {
			hideLoadMoreBtn();
        }
    };

    const updateHrefAttribute = (e) => {
        const { data } = e;

        data.nextPageUrl
            ? el.setAttribute('href', data.nextPageUrl)
            : hideLoadMoreBtn();
    };

    const infiniteScroll = () => {
        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                const { target } = entry;

                if (entry.isIntersecting && !target.getAttribute('disabled')) {
                    target.click();
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0
        });

        observer.observe(el);
    };

    addEventListeners();
    infiniteScroll();
};

export default LoadMore;
