import Cookies from 'js-cookie';

const CookieBanner = el => {
    const button = el.querySelector('.cookie-banner__button .button');
    const cookieName = button.getAttribute('data-cookie-name') || 'cookiesaccepted';
    const expires = parseInt(button.getAttribute('data-cookie-expires')) || 130;
    const cookie = Cookies.get(cookieName);

    const close = (e) => {
        e.preventDefault();
        button.removeEventListener('click', close);
        Cookies.set(cookieName, 'true', { expires: expires });
        el.setAttribute('aria-hidden', true);
    };

    const init = () => {
        button.addEventListener('click', close);
        el.setAttribute('aria-hidden', false);
    };

    if (!cookie) {
        init();
    }
};

export default CookieBanner;
