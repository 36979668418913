import Swiper from 'swiper/bundle';
const els = document.querySelectorAll('.carousel');
const isTouch = ('ontouchstart' in window || navigator.maxTouchPoints);

for (let el of els) {
    new Swiper(el.querySelector('.swiper-container'), {
        slidesPerView: 'auto',
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    });

    if (!isTouch) {
        el.classList.add('carousel--show-navigations');
    }
};
