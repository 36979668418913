const touchDetect = (el, cb) => {
    let touchstartX = 0;
    let touchendX = 0;

    el.addEventListener('touchstart', (e) => {
        touchstartX = e.changedTouches[0].screenX;
    }, false);

    el.addEventListener('touchend', (e) => {
        touchendX = e.changedTouches[0].screenX;

        getSwipeDirection(e);
    }, false);

    const getSwipeDirection = (e) => {
        let direction = null;

        if (touchendX < touchstartX) {
            direction = 'left';
        }

        if (touchendX > touchstartX) {
            direction = 'right';
        }

        cb(direction);
    };
};

export default touchDetect;
