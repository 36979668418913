const Accordion = el => {
    const items = el.querySelectorAll('.accordion__item');
    const SPACE_KEY = 32;
    const ENTER_KEY = 13;

    const open = (item) => {
        const toggleBtn = item.querySelector('.accordion__toggle');
        const content = item.querySelector('.accordion__item-content-holder');

        toggleBtn.setAttribute('aria-expanded', true);
        content.setAttribute('aria-hidden', false);
    };

    const close = (item) => {
        const toggleBtn = item.querySelector('.accordion__toggle');
        const content = item.querySelector('.accordion__item-content-holder');

        toggleBtn.setAttribute('aria-expanded', false);
        content.setAttribute('aria-hidden', true);
    };

    const toggle = (target) => {
        const item = target.parentNode.parentNode;

        if (target.getAttribute('aria-expanded') === 'false') {
            open(item);
        } else {
            close(item);
        }
    };

    const init = () => {
        for (var i = 0; i < items.length; i++) {
            const toggleBtn = items[i].querySelector('.accordion__toggle');

            toggleBtn.addEventListener('click', (e) => {
                e.preventDefault();
                const { target } = e;

                toggle(target);
            });

            toggleBtn.addEventListener('keyup', (e) => {
                const { target } = e;
                if (e.which === SPACE_KEY || e.which === ENTER_KEY) {
                    e.preventDefault();
                    toggle(target);
                }
            });

            toggleBtn.addEventListener('keydown', (e) => {
                if (e.which === SPACE_KEY || e.which === ENTER_KEY) {
                    e.preventDefault();
                }
            });
        }
    };

    init();
};

export default Accordion;
