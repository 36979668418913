const fileUpload = (el) => {
    const fileEl = el.querySelector('.upload-file__file');
    const fileNameEl = el.querySelector('.upload-file__file-name');
    const input = el.querySelector('.upload-file__input');

    const handleFileChange = (e) => {
        const { target } = e;

        if (target.files.length > 0) {
            let fileName = target.files[0].name;
            fileNameEl.innerText = fileName;
            fileEl.style.display = "block";
        } else {
            fileEl.style.display = "none";
        }
    };

    const removeFile = (e) => {
        e.preventDefault();

        input.value = '';
        fileEl.style.display = "none";
    };

    fileEl.addEventListener('click', removeFile);
    input.addEventListener('change', handleFileChange);
};

export default fileUpload;
