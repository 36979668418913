const Foldouts = (foldouts) => {
    const foldoutIds = [...foldouts].map(foldout => foldout.getAttribute('id'));

    const init = () => {
        for (let i = 0; i < foldoutIds.length; i++) {
            const foldoutId = foldoutIds[i];
            const triggers = document.querySelectorAll(`[aria-controls="${foldoutId}"]`);

            for (let trigger of triggers) {
                trigger.addEventListener('click', (e) => toggle(trigger));
            }
        }
    };

    const toggle = (trigger) => {
        const foldoutId = trigger.getAttribute('aria-controls');
        const shouldAutofocus = trigger.getAttribute('data-autofocus');

        if (trigger.getAttribute('aria-expanded') === 'true') {
            document.body.classList.remove('js-no-scroll');
            close(foldoutId);
        } else {
            document.body.classList.add('js-no-scroll');
            open(foldoutId);

            if (shouldAutofocus) {
                triggerAutoFocus(foldoutId);
            }
        }
    };

    const open = (foldoutId) => {
        const foldout = document.getElementById(foldoutId);
        const trigger = document.querySelector(`[aria-controls="${foldoutId}"]`);

        foldout.setAttribute('aria-hidden', 'false');
        trigger.setAttribute('aria-expanded', 'true');
        document.body.classList.add('foldout-open');
    };

    const close = (foldoutId) => {
        const foldout = document.getElementById(foldoutId);
        const trigger = document.querySelector(`[aria-controls="${foldoutId}"]`);

        foldout.setAttribute('aria-hidden', 'true');
        trigger.setAttribute('aria-expanded', 'false');
        document.body.classList.remove('foldout-open');
    };

    const triggerAutoFocus = (foldoutId) => {
        const focusableElements = ['[href], input, select, textarea, [tabindex]:not([tabindex="-1"])'];
        const foldout = document.getElementById(foldoutId);
        const els = foldout.querySelectorAll(focusableElements);

        if (els.length > 0) {
            els[0].focus();
        }
    };

    if (foldouts.length > 0) {
        init();
    };
};

export default Foldouts;
