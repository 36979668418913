const getEvent = (name) => {
    let event;

    try {
        event = new CustomEvent(name);
    } catch (e) {
        event = document.createEvent('Event');
        event.initEvent(name, true, true);
    }

    return event;
};

const animationObserver = (el, opts) => {
    const event = getEvent('animObserverUpdate');

    const defaultOpts = {
        minBreakpoint: 968,
        root: null,
        rootMargin: '0px',
        threshold: 0
    };

    const observer = new IntersectionObserver(function(entries) {
        entries.forEach(entry => {
            const { target } = entry;

            entry.isIntersecting
                ? event['data'] = { isIntersecting: true }
                : event['data'] = { isIntersecting: false };

            target.dispatchEvent(event);
        });
    }, {
        ...defaultOpts,
        ...opts
    });

    const { minBreakpoint } = defaultOpts;
    const mq = window.matchMedia( `(min-width: ${minBreakpoint}px)` );

    if (mq.matches) {
        observer.observe(el);
    }
};

export default animationObserver;
