const NavbarParallax = el => {
	/* eslint-disable */
	// Polyfill for requestAnimationFrame
	window.requestAnimationFrame =
		window.requestAnimationFrame ||
		function(C) {
			return setTimeout(function() {
				C(+new Date());
			}, 30);
		};
	/* eslint-enable */

	const bindScroll = () => {
		window.addEventListener("scroll", scrollHandler, false);
	};

	const scrollHandler = () => {
		window.requestAnimationFrame(update);
	};

	const update = e => {
		const scrollTop = window.pageYOffset;

		if (scrollTop < el.offsetHeight * 2) {
			runAnimation(scrollTop);
		}
	};

	const runAnimation = scrollTop => {
		const speed = 0.4;
		let translateY = Math.ceil(scrollTop * speed);

		el.style.transform = `translateY(-${translateY}px)`;
	};

	const init = () => {
		const viewPortLimit = 769;

		if (window.innerWidth > viewPortLimit) {
			bindScroll();
		}
	};

	init();
};

export default NavbarParallax;
