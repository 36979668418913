import lottie from 'lottie-web';

const lottieAnimation = (opts) => {
    const { rootUrl, el } = opts;
    const minBreakpoint = opts.minBreakpoint ? opts.minBreakpoint : 968;

    const defaultOpts = {
        renderer: 'svg',
        loop: false,
        autoplay: false
    };

    const animate = (e, player) => {
        const { data } = e;

        data.isIntersecting
            ? player.play()
            : player.stop();
    };

    const init = () => {
        const player = lottie.loadAnimation({
            ...defaultOpts,
            ...opts,
            container: el,
            path: `${rootUrl}/animations/${opts.id}.json`
        });

        el.addEventListener('animObserverUpdate', (e) => animate(e, player));
    };

    if (window.innerWidth > minBreakpoint) {
        init();
    };
};

export default lottieAnimation;
