export default function(name) {
    let event;

    try {
        event = new CustomEvent(name);
    } catch (e) {
        event = document.createEvent('Event');
        event.initEvent(name, true, true);
    }

    return event;
}
