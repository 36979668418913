import lottieAnimation from './lottieAnimation';
const els = document.querySelectorAll('.lottie-animation');

for (let el of els) {
    const opts = JSON.parse(el.getAttribute('data-opts'));

    lottieAnimation({
        el,
        ...opts
    });
}
